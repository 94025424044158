
import {Component, Vue, Prop, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
  components: {},
})
export default class DeleteDialog extends Mixins(ObjectInputMixin) {
  @Prop() action!: string;
  @Prop() satis !: boolean;
  @Prop({default: false}) disabled!: boolean;
  @Prop({default: false}) buttonxsmall!: boolean;
  @Prop() messageContent !: any;
  @Prop({default:'#da1e28'}) color!: string;
  @Prop() tooltip!: string;
  @Prop({default:'mdi-trash-can-outline'}) iconName!:string;
  show: boolean = false;
  state: string = "asking";

  get title(): string {
    return "Emin misiniz?";
  }

  get content(): string {
    if (this.messageContent == '' || this.messageContent == null) {
      return "Seçtiğiniz kayıt kalıcı olarak silinecektir. Devam etmek istediğinize emin misiniz?";
    }
    return this.messageContent;
  }

  get iconColor() {
      return this.color;
  }

  ask() {
    this.state = "asking";
  }

  onClose() {
    this.show = false;
  }

  onSubmit() {
    if (this.action) {
      this.state = "deleting";
      this.$http
          .delete(this.action)
          .then(() => {
            this.$emit("success");
            this.state = "asking";
            this.onClose();
          })
          .catch((err) => {
            this.state = "asking";
          });
    } else {
      this.$emit("success");
      this.onClose();
    }
  }
}
