
import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import BuroForm from "@/components/forms/BuroForm.vue";

@Component({
  components: { BuroForm },
})
export default class DeleteBuroDialog extends Mixins(ObjectInputMixin) {
  @Prop() action!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) buttonxsmall!: boolean;
  @Prop() messageContent!: any;
  @Prop({ default: "#da1e28" }) color!: string;
  @Prop() tooltip!: string;
  @Prop({ default: "mdi-delete" }) iconName!: string;
  @Prop({ default: null }) itemId!: number;

  show: boolean = false;
  showEditForm: boolean = false;
  state: string = "asking";
  formData: any = null;

  get isPrimary(): boolean {
    return this.itemId === 1;
  }

  get computedIconName(): string {
    return this.isPrimary ? "mdi-pen" : "mdi-trash-can-outline";
  }

  get title(): string {
    return this.isPrimary ? "Düzenleme İşlemi" : "Silme Onayı";
  }

  get dialogContent(): string {
    if (this.isPrimary) {
      return "Ana büronuzu düzenleyebilirsiniz. Silmek için destek ekibimiz ile iletişime geçmenizi rica ederiz.";
    }
    return (
        this.messageContent ||
        "Seçtiğiniz kayıt kalıcı olarak silinecektir. Devam etmek istediğinize emin misiniz?"
    );
  }

  get iconColor() {
    if (this.isPrimary) {
      return '#5fb5f7';
    }
    return this.color ? this.color : "red";
  }

  ask() {
    this.state = "asking";
  }

  onClose() {
    this.show = false;
  }

  openEditForm() {
    this.loadBuroData();
    this.showEditForm = true;
    this.show = false;
  }

  loadBuroData() {
    this.formData = null;
    this.$http
        .get(`/api/v1/buro/${this.itemId}`)
        .then((response) => {
          this.formData = response.data;
        })
        .catch((err) => {
          console.error("Büro verisi yüklenemedi", err);
        });
  }

  onCloseEditForm() {
    this.showEditForm = false;
  }

  onSubmit() {
    if (this.action && !this.isPrimary) {
      this.state = "deleting";
      this.$http
          .delete(this.action)
          .then(() => {
            this.$emit("success");
            this.state = "asking";
            this.onClose();
          })
          .catch((err) => {
            console.error("Silme işlemi başarısız oldu", err);
            this.state = "asking";
          });
    } else if (!this.isPrimary) {
      this.$emit("success");
      this.onClose();
    }
  }
}
